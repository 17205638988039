import { BodyM, Box } from '@otovo/rainbow';
import { FormattedMessage as T, useIntl } from 'react-intl';

import Head from 'otovoweb/src/components/Head/Head';
import RainbowLink from 'otovoweb/src/components/RainbowLink/RainbowLink';
import { useGlobalConfig } from 'otovoweb/src/configContext';

import ErrorPage from '../ErrorPage/ErrorPage';
import OtovoEmailLink from '../OtovoEmailLink';

import messages from './messages';

type Props = {
  children?: React.ReactNode;
  headTitle?: string;
  headDescription?: string;
  includeContactUs?: boolean;
  title?: React.ReactNode;
  cta?: React.ReactNode;
  status?: number | string;
  illustration?: React.ReactNode;
};

const DefaultChildren = () => {
  const config = useGlobalConfig();

  return (
    <T
      {...messages.notFoundParagraph}
      values={{
        link: (
          <RainbowLink href={config.STOREFRONT_URL}>
            <T {...messages.frontpageRef} />
          </RainbowLink>
        ),
      }}
    />
  );
};

const ErrorStatusPage = ({
  children = <DefaultChildren />,
  headTitle = '',
  headDescription = '',
  includeContactUs = false,
  status = 404,
  cta,
  title = '',
  illustration,
}: Props) => {
  const intl = useIntl();
  return (
    <ErrorPage.Layout>
      <Head
        title={headTitle || intl.formatMessage(messages.headTitle)}
        description={
          headDescription || intl.formatMessage(messages.headDescription)
        }
      />
      <Box display={{ m: 'flex' }}>
        <ErrorPage.Heading color="peach_60">{status}</ErrorPage.Heading>
        <Box ml={{ m: 5 }}>
          <Box
            pl={{ m: 5 }}
            borderLeftStyle="solid"
            borderLeftColor="grey_20"
            borderLeftWidth={{ s: 0, m: 1 }}
          >
            <ErrorPage.Heading>
              {title || <T {...messages.notFoundTitle} />}
            </ErrorPage.Heading>
            {children && (
              <Box mb={5} color="grey_50">
                {children}
              </Box>
            )}
          </Box>
          {cta && <Box ml={{ m: 5 }}>{cta}</Box>}
          {includeContactUs && (
            <BodyM color="grey_50" ml={{ m: 5 }}>
              <T
                {...messages.contactUs}
                values={{
                  email: <OtovoEmailLink />,
                }}
              />
            </BodyM>
          )}
        </Box>
      </Box>
      {illustration && (
        <ErrorPage.Illustration>{illustration}</ErrorPage.Illustration>
      )}
    </ErrorPage.Layout>
  );
};

ErrorStatusPage.displayName = 'ErrorStatusPage';

export default ErrorStatusPage;
